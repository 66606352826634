@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
  }

  p, span, li, a {
    font-family: 'Lato', sans-serif;
  }

  .font-title {
    font-family: 'Montserrat', sans-serif;
  }

  .font-body {
    font-family: 'Lato', sans-serif;
  }

  .font-poppins {
    font-family: 'Poppins', sans-serif;
  }

  .font-roboto {
    font-family: 'Roboto', sans-serif;
  }
}

@layer utilities {
  .text-first-color {
    color: #0B519B;
  }

  .text-second-color {
    color: #EA5B0C;
  }

  .bg-first-color {
    background-color: #0B519B;
  }

  .bg-second-color {
    background-color: #EA5B0C;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #0B519B; 
    border-radius: 10px;
    border: 2px solid #f1f1f1; 
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #EA5B0C;
  }


  scrollbar-width: thin;
  scrollbar-color: #0B519B #f1f1f1;

  
  body::-webkit-scrollbar, 
  .scrollable-container::-webkit-scrollbar {
    width: 12px;
  }

  body::-webkit-scrollbar-track, 
  .scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  body::-webkit-scrollbar-thumb, 
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: #0B519B;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
  }

  body::-webkit-scrollbar-thumb:hover, 
  .scrollable-container::-webkit-scrollbar-thumb:hover {
    background-color: #EA5B0C;
  }
}

@keyframes searchHorizontal {
  0% { transform: translateX(-550%); }
  50% { transform: translateX(0%); }
  100% { transform: translateX(-550%); }
}

.animate-search-horizontal {
  animation: searchHorizontal 2.5s infinite ease-in-out;
}
